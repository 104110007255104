export const INSERT_INTO_SUBSCRIPTION_REQUEST = 'INSERT_INTO_SUBSCRIPTION_REQUEST';
export const INSERT_INTO_SUBSCRIPTION_SUCCESS = 'INSERT_INTO_SUBSCRIPTION_SUCCESS';
export const INSERT_INTO_SUBSCRIPTION_FAIL = 'INSERT_INTO_SUBSCRIPTION_FAIL';

export const GET_BY_ID_SUBSCRIPTION_REQUEST = 'GET_BY_ID_SUBSCRIPTION_REQUEST';
export const GET_BY_ID_SUBSCRIPTION_SUCCESS = 'GET_BY_ID_SUBSCRIPTION_SUCCESS';
export const GET_BY_ID_SUBSCRIPTION_FAIL = 'GET_BY_ID_SUBSCRIPTION_FAIL';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';